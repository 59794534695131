import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Image from "@components/image"
import Anchor from "@components/elements/anchor"
import { slugify } from "@utils/slugify"
import Flex from "@components/elements/flex"

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const AuthorImage = styled.div`
  border-radius: 100%;
  overflow: hidden;
`

const AuthorName = styled.span`
  font-weight: bold;
  line-height: 1;
  color: ${props => props.theme.color.indigo400};
  &:hover {
    color: ${props => props.theme.color.indigo600};
  }
`

const ImageOverride = styled(Image)`
  width: 2rem;
  height: 2rem;
`

const Author = ({ author: { profile_picture, name } }) => {
  return (
    <AuthorContainer>
      <Anchor margin="0 1rem 0 0" link={`/author/${slugify(name)}`}>
        <Flex flexFlow="row" alignItems="center" gap="0.5rem">
          <AuthorImage>
            <ImageOverride file={profile_picture} alt={name} />
          </AuthorImage>
          <AuthorName>{name}</AuthorName>
        </Flex>
      </Anchor>
    </AuthorContainer>
  )
}

Author.propTypes = {
  author: PropTypes.object.isRequired,
}

export default Author
