export function formatSeo(seo, title, description, author, image, template) {
  return {
    title: seo?.title ?? title,
    description: seo?.description ?? description,
    image: seo?.image ?? image,
    tracking: seo?.tracking,
    author: author,
    schema_template: seo?.schema_template ?? template,
    schema: seo?.schema,
  }
}
