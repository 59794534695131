import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Image from "@components/image"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import Span from "@components/elements/span"
import Heading from "@components/elements/heading"

const Card = styled(Link)`
  display: flex;
  flex-flow: column;
`

export const BlogCard = ({ link, title, image, author, date }) => {
  return (
    <Card to={link}>
      <Div borderRadius="0.75rem" overflow="hidden" maxHeight="200px">
        {image && <Image height={200} file={image} alt={image.title} />}
      </Div>
      <Flex margin="1rem 0 0" gap="1rem">
        <Heading as="h4">{title}</Heading>
        {author && date && (
          <Span margin="auto 0 0">
            By <strong>{author}</strong> &#8212; {date}
          </Span>
        )}
      </Flex>
    </Card>
  )
}

BlogCard.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  author: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
}
